import DropDownMenu_TextSeparatorsMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/TextSeparatorsMenuButtonSkin/TextSeparatorsMenuButtonSkin.skin';


const DropDownMenu_TextSeparatorsMenuButtonSkin = {
  component: DropDownMenu_TextSeparatorsMenuButtonSkinComponent
};


export const components = {
  ['DropDownMenu_TextSeparatorsMenuButtonSkin']: DropDownMenu_TextSeparatorsMenuButtonSkin
};

